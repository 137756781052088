@import url('https://fonts.googleapis.com/css2?family=Comfortaa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/omnes-2');

* {
  padding: 0;
  margin: 0;
}

html {
  overflow-x: hidden;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  line-height: 1.6;
  overflow-x: hidden;
  padding-top: 72px;
}

a {
  color: #9259e6;
}
a:hover {
  color: #5e1da1;
}

a.button {
  background-color: #360568;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding: 12px 20px;
  margin: 12px 0px;
  display: inline-block;
}
a.button:hover {
  background-color: #4e0795;
}

a.back {
  text-decoration: none;
  font-size: smaller;
}

h1 {
  font-family: 'Omnes', sans-serif;
  color: #360568;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
}
h1 span.midwinter {
  font-weight: 300;
  color: #390869;
}

h2 {
  font-family: Bebas Neue,Ostrich Sans,Oswald,sans-serif;
  letter-spacing: .1em;
  color: #360568;
  font-size: 3rem;
}

h3 {
  margin-top: 24px;
  color: #360568;
}

section {
  padding: 40px 80px;
}

ul {
  list-style-type: none;
  display: inline-block;
}

p ul {
  list-style-type: inherit;
}
p ul li {
  margin-left: 32px;
}

header {
  position: relative;
  color: white;
  padding: 80px;
  min-height: 360px;
  &::before {
    content: "";
    background: url('./static/images/group.webp');
    background-size: cover;
    background-position: top;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    /* box-shadow: 0 0 200px rgba(0,0,0,0.9) inset; */
  }
}
header a {
  color: white;
}
header a:hover {
  color: #9259e6;
}
nav div {
  flex-grow: 1;
}
nav div.social {
  font-size: 1.4rem;
  text-align: right;
}
nav div.social ul li {
  display: inline-block;
}

section {
  background-color: white;
}
section p {
  margin: 10px 0px;
}

div.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

div.card {
  border: 1px solid #9259e6;
  aspect-ratio: 1/1;
  background-size: cover;
  padding: 10px;
  color: #9259e6;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  border-radius: 0;
}

div.profile {
  display: flex;
  margin-top: 20px;
}

div.profile div.title, p.tagline {
  font-weight: bold;
  color: #360568;
}

div.profile div.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

div.profile img {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 128px;
  border: 8px solid #360568;
  margin-right: 20px;
}

div#call-banner {
  padding: 20px 0px;
  background-color: #360568;
  color: white;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index:1;
}
div#call-banner a {
  color: white;
  text-decoration: none;
}

div#sections {
  max-width: 1280px;
  margin: 0 auto;
}

div#title {
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
}

footer {
  position: relative;
  color: white;
  background-color: #360568;
  padding: 80px;
  margin-top: 40px;
  font-size: 0.9rem;
}
footer a {
  color: white;
}
footer a:hover {
  color: white;
}
footer div.social {
  font-size: 1.2rem;
}
footer div.social ul {
  display: flex;
  margin-top: 20px;
}
footer div.social ul li {
  margin-right: 8px;
}

div#carefully-card {
  background: url('./static/images/carefully.webp');
  background-size: cover;
  background-position: center;
  aspect-ratio: 3/1;
  border: 2px solid #ef199f;
}

nav {
  background-color: #360568;
  padding: 20px;
  display: flex;
  flex-direction: row;
}
nav a, nav a:hover {
  margin-right: 20px;
  font-size: 1rem;
  color: white;
  text-decoration: none;
}
nav a:hover {
  text-decoration: underline;
}

div.google-review {
  position: relative;
  background-color: whitesmoke;
  font-style: italic;
  padding: 20px;
  padding-left: 40px;
  margin: 40px 0px;
  border-left: 6px solid #9259e6;
  font-size: 1rem;
}
div.google-review::before {
  font-family: Arial;
  content: "\201C";
  color:#9259e6;
  font-size:4em;
  position: absolute;
  left: 2px;
  top: -18px;
}

div#pricing > div {
  flex: 1;
}

i.tag {
  font-style: normal;
  background-color: #999;
  color: white;
  border-radius: 8px;
  padding: 2px 5px;
  font-size: 0.8em;
}

i.tag.open {
  background-color: #9259e6;
}

ul.services {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 12px;
}

ul.services li {
  aspect-ratio: 1;
  border-radius: 12px;
  background-color: #360568;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px;
}
ul.services li a {
  color: white;
  text-decoration: none;
  text-align: center;
}

@media screen and (max-width: 920px) {
  body {
    font-size: 1.1rem;
  }
  section, header, footer {
    padding: 40px 40px;
  }
  div.card {
    font-size: 0.9rem;
  }
  h1 {
    font-size: 3.2rem;
  }
  h2 {
    font-size: 2.6rem;
  }
}

@media screen and (max-width: 640px) {
  body {
    font-size: 1rem;
  }
  section, header, footer {
    padding: 40px 20px;
  }
  header {
    min-height: 240px;
  }
  div.cards {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2px;
  }
  div.card {
    aspect-ratio: 16/9;
  }
  h1 {
    font-size: 2.8rem;
  }
  h2 {
    font-size: 2rem;
  }
  div#carefully-card {
    aspect-ratio: 2/1;
  }
  nav {
    flex-direction: column;
  }
  nav div:first-child {
    justify-content: center;
  }
  nav div:last-child {
    padding-top: 10px;
    text-align: center !important;
  }
  ul.services {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
